import { invokeApi } from "src/bl_libs/invokeApi";

export const add_new_event_api = async (data) => {
  const requestObj = {
    path: `api/coach_calendar/add_event`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_event_api = async (data) => {
  const requestObj = {
    path: `api/coach_calendar/delete`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const connect_with_google = async (data) => {
  const requestObj = {
    path: `api/coach_calendar/add_user_calendar_event`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const remove_google_access_api = async () => {
  const requestObj = {
    path: `api/coach_calendar/remove_google_access/remove`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const new_event_list_api = async (data) => {
  const requestObj = {
    path: `api/coach_calendar/list`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_event_iteration_api = async (data, event_slug) => {
  const requestObj = {
    path: `api/coach_calendar/event_iteration/update/by_member/${event_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const event_status_change_api = async (data, id, type) => {
  const requestObj = {
    path: `api/coach_calendar/complete/${id}?type=${type}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
