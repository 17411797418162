import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { IconButton, DialogContent, DialogActions } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useState } from "react";

// import AddWorkoutCalender from "../AddWorkoutCalender";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import AddWorkoutCalender from "./AddWorkoutCalender";
import AddWorkoutCalenderClient from "./AddWorkoutCalenderClient";

export default function EditWorkoutPopupClient({
  open,
  setOpen,
  date,
  programme,
  data,
  reloadList,
  member_id,
  member_vdot,
  day,
  events,
  setEvents,
}) {
  const [smallScreen, setSmallScreen] = useState("");
  const [openCloseDialog, setOpenCloseDialog] = useState(false);
  const childRef = React.useRef();
  const [modalChangeExe, setModalChangeExe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Function to be called in the child component
  const handleParentButtonClick = () => {
    // Call the exposed function in the child component using the ref
    childRef.current.childButtonClickUpdate();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleAgreeDelete = (value) => {
    // setDeleteDoc(value);
    setOpenCloseDialog(true);
  };
  const handleDelete = async () => {
    handleClose();
    setOpenCloseDialog(false);
  };
  React.useEffect(() => {
    if (open) {
      setModalChangeExe(false);
      setIsLoading(false);
    }
  }, [open]);
  return (
    <div>
      <CustomConfirmation
        openDelete={openCloseDialog}
        setOpenDelete={setOpenCloseDialog}
        title={"Are you sure you want to Close ?"}
        handleAgree={handleDelete}
      />
      <Dialog
        open={open}
        disableEnforceFocus={true}
        // onClose={handleClose}
        // scroll="body"
        fullScreen={window.innerWidth <= 550 ? true : false}
        fullWidth
        maxWidth={"lg"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="show-dialog-on-full"
      >
        <div className="text-end mt-2 me-2">
          {modalChangeExe ? (
            <IconButton
              className="back-screen-button"
              onClick={handleAgreeDelete}
            >
              <CloseIcon />
            </IconButton>
          ) : (
            <IconButton className="back-screen-button" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          )}
        </div>

        <DialogContent
          sx={{
            paddingY: 0,
            height: "80vh", // Set a maximum height for the scrollable area
            overflowY: "auto", // Enable vertical scrolling when content overflows
          }}
        >
          <AddWorkoutCalenderClient
            setIsLoading={setIsLoading}
            ref={childRef}
            date={data?.workout_date}
            programme={programme}
            member_id={member_id}
            setOpen={setOpen}
            editData={data}
            day={day}
            reloadList={reloadList}
            setModalChangeExe={setModalChangeExe}
            member_vdot={member_vdot}
            events={events}
            setEvents={setEvents}
          />
        </DialogContent>
        <DialogActions>
          {data?.workout_has_logs ? (
            <Button
              disabled={isLoading}
              sx={{ textTransform: "inherit" }}
              onClick={isLoading ? "" : handleParentButtonClick}
              size="small"
              color="primary"
              variant="contained"
              className="small-contained-dialog-button"
            >
              Save
            </Button>
          ) : (
            <Button
              disabled={isLoading}
              sx={{ textTransform: "inherit" }}
              onClick={isLoading ? "" : handleParentButtonClick}
              size="small"
              color="primary"
              variant="contained"
              className="small-contained-dialog-button"
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
