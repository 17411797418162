import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Autocomplete,
  InputAdornment,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Card,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { get_root_value } from "src/utils/domUtils";
import { useState } from "react";
import { s3baseUrl } from "src/config/config";

import AddWorkoutCalender from "../AddWorkoutCalender";
import AddWorkoutCalenderModel from "./AddWorkoutCalenderModel";
import { AllActiveWorkoutTypeListingApi } from "src/DAL/workoutType/workoutType";
import { useEffect } from "react";

import { Icon } from "@iconify/react";
import { WorkoutListingApi } from "src/DAL/workout/workout";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { useSnackbar } from "notistack";
import moment from "moment";
import {
  add_edit_program_workout_member_api,
  add_program_workout_api,
} from "src/DAL/programmeWorkout/programmeWorkout";
import AddWorkoutCalenderClient from "../AddWorkoutCalenderClient";
import { convertToTitleCase } from "src/utils/convertHtml";
import { handleScrollCalled, is_small_medium_screen } from "src/utils/constant";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { Visibility } from "@mui/icons-material";
import CustomPopover from "src/pages/UserInformation/CustomPopover";
import DisplaySet from "src/pages/Banner/component/DisplaySet";
import ReactVideoPlayer from "src/components/ReactVideoPlayer/ReactVideoPlayer";
import WorkoutDetail from "./WorkoutDetail";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "10%",
    marginBottom: "10%",
  },
}));
export default function AddWorkoutPopupClient({
  open,
  setOpen,
  date,
  programme,
  reloadList,
  day,
  member_id,
  member_vdot,
  setEditWorkputData,
  events,
  setEvents,
}) {
  const { setUserExerciseHistory } = useContentSetting();
  const [selectedOption, setSelectedOption] = useState("existing");
  const [selectedPracticeTypeProgram, setSelectedPracticeTypeProgram] =
    useState("workout");
  const [selectedPracticeTypeOption, setSelectedPracticeTypeOption] =
    useState("workout");
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const [workouts, setWorkouts] = useState([]);
  const [dialogWidth, setDialogWidth] = useState("sm");
  const [dialogFullScreen, setDialogFullScreen] = useState(false);
  const [multipleType, setMultipleType] = useState([]);

  const [selectedDetail, setSelectedDetail] = useState(null);
  const [openselectedDetails, setOpenselectedDetails] = useState(false);

  const handleIconClick = (workout) => {
    setSelectedDetail(workout);
    setOpenselectedDetails(true);
  };

  const [workoutType, setWorkoutType] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalChangeExe, setModalChangeExe] = useState(false);
  const [inputs, setInputs] = React.useState({
    title: "",
    workout: null,
    program: "",
    difficulty_levels: [],
    is_disable: true,
  });
  const [newWorkout, setNewWorkout] = useState(false);

  const [serchText, setSerchText] = useState("");
  const [deleteDoc, setDeleteDoc] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [workoutAsTem, setWorkoutAsTem] = useState();
  const [openCloseDialog, setOpenCloseDialog] = useState(false);
  const childRef = React.useRef();

  // Function to be called in the child component
  const handleParentButtonClick = () => {
    // Call the exposed function in the child component using the ref
    childRef.current.childButtonClick();
  };
  const handleParentSaveAsTemplateButtonClick = () => {
    // Call the exposed function in the child component using the ref
    childRef.current.childButtonClick2();
  };
  const handleChangeType = (event) => {
    const {
      target: { value },
    } = event;
    setMultipleType(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleClose = () => {
    setOpen(false);
    setInputs((values) => ({ ...values, workout: null, program: "" }));
  };

  const handleSaveExistingWorkout = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (selectedPracticeTypeProgram == "video" && multipleType.length <= 0) {
      enqueueSnackbar(`Please Select Type`, {
        variant: "error",
      });
      setIsLoading(false);

      return;
    }
    if (programme?.program && !inputs.program) {
      setIsLoading(false);

      enqueueSnackbar("please select Program", { variant: "error" });
      return;
    }
    if (!inputs.workout) {
      setIsLoading(false);

      enqueueSnackbar(
        `please select ${convertToTitleCase(selectedPracticeTypeOption)}`,
        { variant: "error" }
      );
      setIsLoading(false);

      return;
    }

    const exerciseId = inputs?.workout?.exercises?.map((item) => {
      console.log(item, "sfdhsdjkfa");
      let dataToSend = { ...item };
      dataToSend.exercise_info = item.exercise_info;

      return dataToSend;
    });
    const formDataObject = {
      title: inputs.title,

      description: inputs?.workout?.description,
      difficulty_levels: inputs.difficulty_levels,
      exercises: exerciseId,
      member_id: member_id,
      program_id: programme?._id?._id
        ? programme?._id?._id
        : inputs?.program?._id?._id,

      workout_date: moment(date).format("YYYY-MM-DD"),
      practice_type: selectedPracticeTypeOption
        ? selectedPracticeTypeOption
        : "workout",
      video_url: inputs?.workout?.video_url,
      video_thumbnail: inputs?.workout?.video_thumbnail,
    };
    const formData = new FormData();

    formData.append("title", formDataObject.title);
    formData.append("description", formDataObject.description);
    formData.append("member_id", formDataObject.member_id);
    formData.append("program_id", formDataObject.program_id);
    formData.append("workout_date", formDataObject.workout_date);
    formData.append("practice_type", formDataObject.practice_type);
    formData.append(
      "difficulty_levels",
      JSON.stringify(formDataObject.difficulty_levels)
    );
    formData.append("exercises", JSON.stringify(formDataObject.exercises));
    formData.append(
      "video_url",
      formDataObject.video_url ? formDataObject.video_url : ""
    );
    if (formDataObject.video_thumbnail) {
      formData.append(
        "duplicate_image_path",
        JSON.stringify(formDataObject.video_thumbnail)
      );
    }
    if (selectedPracticeTypeProgram == "video") {
      formData.append("video_only_type", JSON.stringify(multipleType));
    }
    console.log(
      formDataObject,
      "this is called on save just",
      inputs.workout,
      programme
    );
    console.log(...formData, "=====helo data ");
    const result = await add_edit_program_workout_member_api(formData);
    if (result.code === 200) {
      setEditWorkputData(result?.workout);
      const workout = result?.workout;
      let newData = {
        ...workout,
        title: workout.title ? workout.title : "",
        color: workout.program_info?.color,
        workout_color: workout.program_info?.color,
        workout_title: workout.title ? workout.title : "",
        workout_exercises: workout.exercises,
        workout_program: workout.program_info,
        is_deleteAble: workout?.workout_has_logs ? false : true,
        workout_completed: workout?.workout_completed,
        status: workout.status,
        open: true,
        date: moment(workout.workout_date).format("YYYY-MM-DD"),
        // index: events.length - 1,
        editable: workout?.workout_has_logs ? false : true,
      };
      // let list = [...events, newData];
      // setEvents(list);
      // let lastIndex = -1;
      // for (let i = 0; i < events.length; i++) {
      //   if (events[i].workout_date === newData.workout_date) {
      //     lastIndex = i;
      //   }
      // }
      // let list = [];
      // if (lastIndex === -1) {
      //   list = [...events, newData];
      //   setEvents(list);
      // } else {
      //   events.splice(lastIndex + 1, 0, newData);
      //   let all_events = events.map((workout, i) => {
      //     return {
      //       ...workout,
      //       order: i,
      //       index: i,
      //     };
      //   });
      //   setEvents(all_events);
      //   console.log(all_events, "all_eventsall_eventsall_events");
      // }
      handleScrollCalled(workout?._id);

      reloadList();
      setIsLoading(false);
      setOpen(false);
      setInputs((values) => ({ ...values, workout: null, program: "" }));
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleContinue = () => {
    if (selectedPracticeTypeProgram == "video" && multipleType.length <= 0) {
      enqueueSnackbar(`Please Select Type`, {
        variant: "error",
      });
      setIsLoading(false);

      return;
    }

    if (
      programme?._id?.difficulty_level &&
      inputs.difficulty_levels.length === 0 &&
      selectedOption !== "existing"
    ) {
      enqueueSnackbar(`Please Select Levels`, {
        variant: "error",
      });
      setIsLoading(false);
      return;
    }
    setNewWorkout(true);
  };
  const handleAgreeDelete = (value) => {
    // setDeleteDoc(value);
    setOpenCloseDialog(true);
  };
  const handleDelete = async () => {
    handleClose();
    setOpenCloseDialog(false);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleOptionPracticeTypChange = (event) => {
    setSelectedPracticeTypeOption(event.target.value);
  };
  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const openSaveAsDialog = () => {
    setOpen(false);
    setModalOpen(true);
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const getWorkoutTypes = async () => {
    try {
      const result = await AllActiveWorkoutTypeListingApi(
        workoutAsTem?.practice_type
      );
      if (result.code === 200) {
        // console.log(result, "dfkjsdahaf");
        setWorkoutType(result?.workout_types);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {}
  };
  const getExerciseList = async () => {
    let postData = {
      workout_type: "",
      status: "active",
      practice_type: selectedPracticeTypeOption,
    };
    let result = await WorkoutListingApi(0, 100, serchText, postData);
    if (result.code == 200) {
      console.log(result, "kfaskjdfkla");
      // setIsLoading(false);

      setWorkouts(result?.workouts);
    }
  };

  useEffect(() => {
    if (
      inputs.workout &&
      inputs.workout.difficulty_levels &&
      selectedOption !== "existing"
    ) {
      setInputs((values) => ({
        ...values,
        difficulty_levels: inputs.workout.difficulty_levels,
      }));
    }
  }, [inputs.workout]);

  useEffect(() => {
    if (inputs.program) {
      setInputs((values) => ({
        ...values,
        difficulty_levels: inputs?.program?.member_difficutly_level
          ? [inputs?.program?.member_difficutly_level]
          : [],
        is_disable:
          inputs?.program?.program_length_type == "limited"
            ? !!inputs?.program?.start_date
            : true,
      }));
    }
  }, [inputs.program]);

  useEffect(() => {
    getExerciseList();
  }, [serchText, selectedPracticeTypeOption]);
  useEffect(() => {
    getWorkoutTypes();
  }, []);
  React.useEffect(() => {
    if (workoutAsTem) setModalOpen(true);
  }, [workoutAsTem]);
  useEffect(() => {
    if (modalOpen) getWorkoutTypes();
  }, [modalOpen]);
  React.useEffect(() => {
    if (newWorkout) setDialogWidth("xl");
    setModalChangeExe(false);
  }, [newWorkout]);
  React.useEffect(() => {
    if (open) {
      setUserExerciseHistory([]);
      setDialogWidth("sm");
      setNewWorkout(false);
      setWorkoutAsTem("");
      setModalChangeExe(false);
      setSelectedOption("existing");
      setInputs((values) => ({
        ...values,
        workout: "",
        title: "",
        difficulty_levels: programme?.member_difficutly_level
          ? [programme?.member_difficutly_level]
          : [localStorage.getItem("difficultyLevel") || ""],
      }));
      setSelectedPracticeTypeOption(
        programme?._id?.program_type == "video" ? "video" : "workout"
      );
      setSelectedPracticeTypeProgram(
        programme?._id?.program_type == "video" ? "video" : "general"
      );
    }
  }, [open]);

  let programe_list = [];

  if (
    programme?.program?.length > 0 &&
    programme?.difficult_program?.length > 0
  ) {
    programe_list = programme.program.filter(
      (item) =>
        !programme.difficult_program.some(
          (difficult) => difficult.program_id === item._id._id
        )
    );
  } else {
    programe_list = programme.program;
  }

  return (
    <div>
      <AddWorkoutCalenderModel
        onOpen={modalOpen}
        setOnOpen={setModalOpen}
        workoutTypes={workoutType}
        workoutAsTem={workoutAsTem}
      />
      <CustomConfirmation
        openDelete={openCloseDialog}
        setOpenDelete={setOpenCloseDialog}
        title={"Are you sure you want to Close ?"}
        handleAgree={handleDelete}
      />
      <Dialog
        open={open}
        disableEnforceFocus={true}
        // onClose={handleClose}
        // scroll="body"

        // fullScreen={
        //   dialogWidth == "xl" && window.innerWidth <= 550
        //     ? true
        //     : dialogWidth == "xl"
        //     ? true
        //     : false
        // }
        fullScreen={
          dialogWidth == "xl" && window.innerWidth <= 550 ? true : false
        }
        fullWidth
        maxWidth={dialogWidth}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="show-dialog-on-full"
      >
        {!newWorkout ? (
          <div
            className={`text-end mt-1 d-flex justify-content-between align-items-center`}
          >
            <DialogTitle
              sx={{
                paddingY: 0,
                color: get_root_value("--portal-theme-primary"),
              }}
            >
              Choose an Option
            </DialogTitle>

            <IconButton
              className="back-screen-button me-1 "
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
        ) : (
          <div className={`text-end mt-1 me-1  `}>
            {modalChangeExe ? (
              <IconButton
                className="back-screen-button"
                onClick={handleAgreeDelete}
              >
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton
                className="back-screen-button me-1 "
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>
        )}

        <>
          {!newWorkout ? (
            isLoading ? (
              <CircularProgress className={classes.loading} color="primary" />
            ) : (
              <DialogContent
                sx={{
                  // paddingY: 0,
                  maxHeight: "60vh", // Set a maximum height for the scrollable area
                  overflowY: "auto", // Enable vertical scrolling when content overflows
                }}
              >
                {selectedPracticeTypeProgram == "video" ? (
                  // <FormControl component="fieldset" fullWidth sx={{ mb: 2 }}>
                  //   <InputLabel id="select-label">Select Type</InputLabel>
                  //   <Select
                  //     labelId="select-label"
                  //     id="select"
                  //     value={selectedPracticeTypeOption}
                  //     onChange={handleOptionPracticeTypChange}
                  //     label="Select Type">
                  //     <MenuItem value="video">Video</MenuItem>
                  //   </Select>
                  // </FormControl>
                  <FormControl
                    component="fieldset"
                    required
                    fullWidth
                    sx={{ mb: 2 }}
                  >
                    <InputLabel id="select-label">Select Type</InputLabel>
                    <Select
                      labelId="select-label"
                      id="select"
                      multiple
                      // required
                      value={multipleType}
                      onChange={handleChangeType}
                      label="Select Type"
                    >
                      <MenuItem value="workout">Workout</MenuItem>
                      <MenuItem value="food">Food Matters</MenuItem>
                      <MenuItem value="mindset">Mindset</MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <FormControl component="fieldset" fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="select-label">Select Type</InputLabel>
                    <Select
                      labelId="select-label"
                      id="select"
                      value={selectedPracticeTypeOption}
                      onChange={handleOptionPracticeTypChange}
                      label="Select Type"
                    >
                      <MenuItem value="workout">Workout</MenuItem>
                      <MenuItem value="food">Food Matters</MenuItem>
                      <MenuItem value="mindset">Mindset</MenuItem>
                      <MenuItem value="video">Instructional Video</MenuItem>
                    </Select>
                  </FormControl>
                )}

                <FormControl component="fieldset" fullWidth>
                  <InputLabel id="select-label">Select Option</InputLabel>
                  <Select
                    labelId="select-label"
                    id="select"
                    value={selectedOption}
                    onChange={handleOptionChange}
                    label="Select Option"
                  >
                    <MenuItem value="existing">
                      Use From {convertToTitleCase(selectedPracticeTypeOption)}{" "}
                      Library
                    </MenuItem>
                    <MenuItem value="new">
                      New {convertToTitleCase(selectedPracticeTypeOption)}
                    </MenuItem>
                  </Select>
                </FormControl>
                {programme?.program && (
                  <Autocomplete
                    id="tags-outlined"
                    sx={{ mt: 2 }}
                    options={programe_list}
                    getOptionLabel={(option) => option.title}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Avatar
                            src={
                              s3baseUrl +
                              option?._id?.program_images?.thumbnail_1
                            }
                            alt={option?._id?.title}
                            sx={{
                              width: 30,
                              height: 30,
                              marginRight: 1,
                              borderRadius: "50%",
                            }}
                          />
                          {option?._id?.title}
                        </div>
                      </li>
                    )}
                    filterSelectedOptions
                    value={inputs.program || null}
                    onChange={(event, newValue) => {
                      setInputs((values) => ({
                        ...values,
                        program: newValue,
                      }));
                      setSelectedPracticeTypeProgram(
                        newValue?._id?.program_type == "video"
                          ? "video"
                          : "general"
                      );
                      setSelectedPracticeTypeOption(
                        newValue?._id?.program_type == "video"
                          ? "video"
                          : "workout"
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Program"
                        placeholder="Program"
                        // onChange={handleSearch}
                        InputProps={{
                          ...params.InputProps,
                          style: { paddingLeft: "6px" },
                          startAdornment: (
                            <InputAdornment position="start">
                              {inputs?.program?._id?.program_images
                                ?.thumbnail_1 && (
                                <Avatar
                                  sx={{ borderRadius: "5px" }}
                                  alt="User Image"
                                  src={
                                    s3baseUrl +
                                    inputs?.program?._id?.program_images
                                      ?.thumbnail_1
                                  }
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                )}
                {programme?._id?.difficulty_level ||
                inputs.program?._id?.difficulty_level ? (
                  <>
                    {selectedOption !== "existing" && (
                      <FormControl fullWidth sx={{ mt: 2 }} required>
                        <InputLabel id="demo-simple-select-label">
                          Difficulty Levels
                        </InputLabel>
                        <Select
                          disabled={inputs.is_disable}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          multiple
                          name="difficulty_levels"
                          value={inputs.difficulty_levels}
                          label="Difficulty Levels"
                          onChange={handleChange}
                        >
                          <MenuItem value="novice">Novice</MenuItem>
                          <MenuItem value="intermediate">Intermediate</MenuItem>
                          <MenuItem value="advanced">Advanced</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  </>
                ) : (
                  ""
                )}
                {selectedOption == "existing" && (
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                      <Autocomplete
                        id="tags-outlined"
                        options={workouts}
                        getOptionLabel={(option) => option.title}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Avatar
                                src={s3baseUrl + option?.image?.thumbnail_1}
                                alt={option.title}
                                sx={{
                                  width: 30,
                                  height: 30,
                                  marginRight: 1,
                                  borderRadius: "50%",
                                }}
                              />
                              <span style={{ flexGrow: 1 }}>
                                {option.title}
                              </span>
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleIconClick(option);
                                }}
                              >
                                <Visibility
                                  style={{
                                    fill: "var(--portal-theme-primary)",
                                    fontSize: "18px",
                                  }}
                                />
                              </IconButton>
                            </div>
                          </li>
                        )}
                        filterSelectedOptions
                        value={inputs.workout || null}
                        onChange={(event, newValue) => {
                          setInputs((values) => ({
                            ...values,
                            workout: newValue,
                            title: newValue?.title || "",
                          }));
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${convertToTitleCase(
                              selectedPracticeTypeOption
                            )}`}
                            placeholder={`${convertToTitleCase(
                              selectedPracticeTypeOption
                            )}`}
                            onChange={handleSearch}
                            InputProps={{
                              ...params.InputProps,
                              style: { paddingLeft: "6px" },
                              startAdornment: (
                                <InputAdornment position="start">
                                  {inputs?.workout?.image?.thumbnail_1 && (
                                    <Avatar
                                      sx={{ borderRadius: "5px" }}
                                      alt="User Image"
                                      src={
                                        s3baseUrl +
                                        inputs?.workout?.image?.thumbnail_1
                                      }
                                    />
                                  )}
                                </InputAdornment>
                              ),
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                      <TextField
                        id="outlined-basic"
                        label={`${convertToTitleCase(
                          selectedPracticeTypeOption
                        )} Name`}
                        variant="outlined"
                        fullWidth
                        required
                        name="title"
                        value={inputs.title}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                )}
              </DialogContent>
            )
          ) : (
            <>
              <DialogContent
                sx={{
                  paddingY: 0,
                  height: "80vh", // Set a maximum height for the scrollable area
                  overflowY: "auto", // Enable vertical scrolling when content overflows
                }}
              >
                <AddWorkoutCalenderClient
                  ref={childRef}
                  date={date}
                  programme={
                    programme?.program && programme?.program.length > 0
                      ? inputs.program
                      : programme
                  }
                  setOpen={setOpen}
                  member_id={member_id}
                  setWorkoutAsTem={setWorkoutAsTem}
                  reloadList={reloadList}
                  events={events}
                  setEvents={setEvents}
                  setEditWorkputData={setEditWorkputData}
                  setIsLoading={setIsLoading}
                  day={day}
                  setModalChangeExe={setModalChangeExe}
                  member_vdot={member_vdot}
                  practice_type={selectedPracticeTypeOption}
                  action_type={"ADD"}
                  multipleTypes={multipleType}
                  setMultipleType={setMultipleType}
                  inputs={inputs}
                />
              </DialogContent>
            </>
          )}
          {selectedOption == "existing" ? (
            <DialogActions>
              <Button
                disabled={isLoading}
                sx={{ textTransform: "inherit" }}
                onClick={isLoading ? "" : handleSaveExistingWorkout}
                size="small"
                color="primary"
                variant="contained"
                className="small-contained-dialog-button"
              >
                Save
              </Button>
            </DialogActions>
          ) : !newWorkout ? (
            <DialogActions>
              <Button
                onClick={() => {
                  handleContinue();
                }}
                sx={{ textTransform: "inherit" }}
                size="small"
                color="primary"
                variant="contained"
                className="small-contained-dialog-button"
              >
                Continue
              </Button>
            </DialogActions>
          ) : (
            <DialogActions>
              <Button
                disabled={isLoading}
                onClick={isLoading ? "" : handleParentButtonClick}
                sx={{ textTransform: "inherit" }}
                size="small"
                color="primary"
                variant="contained"
                className="small-contained-dialog-button"
              >
                Save only
              </Button>
              <Button
                disabled={isLoading}
                onClick={() => {
                  // openSaveAsDialog();
                  isLoading ? "" : handleParentSaveAsTemplateButtonClick();
                }}
                size="small"
                color="primary"
                variant="contained"
                className="small-contained-dialog-button"
                sx={{ textTransform: "inherit" }}
              >
                Save and save as template
              </Button>
            </DialogActions>
          )}
        </>
      </Dialog>
      {selectedDetail && (
        <CustomPopover
          isOpenPop={openselectedDetails}
          isClosePop={setOpenselectedDetails}
          title={
            <div className="d-flex align-items-center">
              {selectedDetail?.image?.thumbnail_1 && (
                <img
                  width="50"
                  height="50"
                  style={{ borderRadius: "5px", marginRight: "10px" }}
                  src={s3baseUrl + selectedDetail?.image?.thumbnail_1}
                />
              )}
              <Typography variant="h6">{selectedDetail?.title}</Typography>
            </div>
          }
          componentToPassDown={
            <div className="row px-4 pb-3">
              <WorkoutDetail
                selectedPracticeTypeOption={selectedPracticeTypeOption}
                selectedDetail={selectedDetail}
              />
            </div>
          }
        />
      )}
    </div>
  );
}
