import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import Label from "src/components/Label";
import AddOrUpdateTickets from "./AddOrUpdateTickets";
import CustomMUITable from "src/components/CustomMUITable";
import {
  DeleteSupportTicket,
  get_list_support_ticket_with_pagination,
  markResolved,
} from "src/DAL/SupportTicket/SupportTicket";
import { timeAgo } from "src/utils/constants";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomDrawer from "src/components/CustomDrawer/CustomDrawer";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
function SupportTicketsTabs({
  tabValue,
  valueData,
  setSolved,
  setOpen,
  setUserList,
  userList,
}) {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [editDrawerState, setEditDrawerState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [editData, setEditData] = useState({});
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageCount, setPageCount] = useState(1);
  const [ticketId, setTicketId] = useState("");
  const [openResolved, setOpenResolved] = useState(false);
  const path = window.location.pathname;

  const getTickestsListing = async (value) => {
    setIsLoading(true);
    const formData = new FormData();
    if (!value) {
      formData.append("filter_by", "all");
    } else {
      formData.append("filter_by", value);
    }

    const result = await get_list_support_ticket_with_pagination(
      valueData,
      page,
      rowsPerPage,
      ""
    );
    if (result.code === 200) {
      const data = result.support_ticket.map((ticket) => {
        let timeCreated = timeAgo(ticket.support_ticket_date);
        let resolvedData = moment
          .utc(ticket.resolve_date)
          .format("DD-MM-YYYY hh:mm A");

        return {
          ...ticket,
          timeCreated,
          resolvedData,
        };
      });
      setOpen(result.open_ticket_count);
      setSolved(result.solved_ticket_count);
      setTotalCount(result?.total_count);
      setTotalPages(result?.total_pages);
      setUserList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const hanleChangePage = (value) => {
    navigate(`/support-ticket/${value._id}`, {
      state: { value, tabValue },
    });
  };

  const handleAgreeDelete = (value) => {
    setTicketId(value._id);
    setOpenDelete(true);
  };

  const handleEdit = (value) => {
    setEditData(value);
    setEditDrawerState(true);
  };

  const handleOpenEditDrawer = () => {
    setEditDrawerState(true);
  };

  const handleMarkResolvedPopup = (value) => {
    setTicketId(value._id);
    setOpenResolved(true);
  };

  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "reference_number", label: "#" },
    {
      id: "subject",
      label: "Subject",
      renderData: (data) => {
        const truncatedSubject =
          data.subject.length > 40
            ? data.subject.substring(0, 40) + "..."
            : data.subject;
        return <div>{truncatedSubject}</div>;
      },
    },
    { id: "timeCreated", label: "Created At" },
    valueData === "open"
      ? {
          id: "ticket_status",
          label: "Status",
          renderData: (data) => {
            let ticket_status = data.ticket_status;
            let response_status = data.response_status;
            return (
              <Label
                variant="ghost"
                className={
                  ticket_status === 0 && response_status == 1
                    ? "answer-ticket"
                    : ticket_status == 1
                    ? "solved-ticket"
                    : ticket_status == 2
                    ? "trash-ticket"
                    : "pending-ticket"
                }
              >
                {ticket_status === 0 && response_status == 1
                  ? "Answered"
                  : ticket_status == 1
                  ? "Closed"
                  : ticket_status == 2
                  ? "Trash"
                  : "waiting"}
              </Label>
            );
          },
        }
      : { id: "resolvedData", label: "Resolved Date" },
  ];

  const MENU_OPTIONS = [
    {
      label: "Detail",
      icon: "gg:details-more",
      handleClick: hanleChangePage,
    },
    ...(valueData === "open"
      ? [
          {
            label: "Edit",
            icon: "akar-icons:edit",
            handleClick: handleEdit,
          },
        ]
      : []),
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    ...(valueData === "open"
      ? [
          {
            label: "Mark Resolved",
            icon: "emojione-monotone:heavy-check-mark",
            handleClick: handleMarkResolvedPopup,
          },
        ]
      : []),
  ];

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeleteSupportTicket(ticketId);
    if (result.code === 200) {
      getTickestsListing("all");
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleMarkResolved = async () => {
    setOpenResolved(false);
    setIsLoading(true);
    const result = await markResolved(ticketId);
    if (result.code === 200) {
      getTickestsListing("all");
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/support-ticket`);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTickestsListing();
  }, [rowsPerPage, page, path]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container mt-4 p-0">
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        MENU_OPTIONS={MENU_OPTIONS}
        data={userList}
        className="card-with-background"
        custom_pagination={{
          total_count: totalCount,
          rows_per_page: rowsPerPage,
          page: page,
          handleChangePage: handleChangePage,
          onRowsPerPageChange: handleChangeRowsPerPage,
        }}
        pageCount={pageCount}
        totalPages={totalPages}
        handleChangePages={handleChangePages}
        rows_per_page_count={rowsPerPage}
        hide_search={true}
      />
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Ticket?"}
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        open={openResolved}
        setOpen={setOpenResolved}
        title={"Are you sure you want to mark this ticket as resolved?"}
        handleAgree={handleMarkResolved}
      />
      <CustomDrawer
        isOpenDrawer={editDrawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Edit Support Ticket"
        componentToPassDown={
          <AddOrUpdateTickets
            editData={editData}
            formType="EDIT"
            dataList={getTickestsListing}
            onCloseDrawer={handleCloseEditDrawer}
          />
        }
      />
    </div>
  );
}
export default SupportTicketsTabs;
