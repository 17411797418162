import React, { useEffect, useRef, useState } from "react";
// import { ProgressBar } from "react-scroll-progress-bar";
import { IconButton, CircularProgress } from "@mui/material";
import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { makeStyles } from "@mui/styles";
import "./WorkoutDisplay.css"; // Import your custom CSS file for styling
import { Card, Typography, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Icon } from "@iconify/react";
import MySlider from "./component/slider";
import { GymTVProgramsListingApi } from "src/DAL/GymTv/gymTv";
import ModalToSetprogram from "./component/ModalToSetprogram";
import RecordNotFound from "src/components/RecordNotFound";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const WorkoutDisplay = () => {
  const classes = useStyles();
  const [openProgramSelecter, setProgramSelecter] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [isPaused, setIsPaused] = useState(true);
  const [inputs, setInputs] = useState({
    programs: [],
    programs_id: null,
    workout_of_day: null,
  });
  const [selectedWorkout, setSelectedWorkout] = useState({
    programs: [],
    programs_id: null,
    workout_of_day: null,
  });

  const navigate = useNavigate();
  const [zoomLevel, setZoomLevel] = useState(100);
  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => prevZoom + 10);
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(10, prevZoom - 10));
  };
  const getWorkoutTypes = async () => {
    try {
      const result = await GymTVProgramsListingApi();
      if (result.code === 200) {
        setData(result?.program_workouts);

        setInputs((values) => ({
          ...values,
          programs: result?.program_workouts,
          programs_id: result?.program_workouts[0],
          workout_of_day: result?.program_workouts[0]?.workouts[0],
        }));
        setSelectedWorkout((values) => ({
          ...values,
          programs: result?.program_workouts,
          programs_id: result?.program_workouts[0],
          workout_of_day: result?.program_workouts[0]?.workouts[0],
        }));
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  // Pause interval
  const handlePause = () => {
    setIsPaused(true);
  };

  // Play interval
  const handlePlay = () => {
    setIsPaused(false);
  };
  useEffect(() => {
    getWorkoutTypes();
  }, []);
  useEffect(() => {
    openProgramSelecter && setInputs(selectedWorkout);
  }, [openProgramSelecter]);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return data.length > 0 ? (
    <div className="container">
      <ModalToSetprogram
        open={openProgramSelecter}
        setOpen={setProgramSelecter}
        inputs={inputs}
        setInputs={setInputs}
        setSelectedWorkout={setSelectedWorkout}
      />
      <div className="row mt-4 align-items-center">
        {isPaused ? (
          <>
            <div className="d-flex align-items-center justify-content-start col-md-11 col-sm-12">
              <IconButton
                className="back-screen-button "
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
              </IconButton>
              <h2
                onClick={() => {
                  setProgramSelecter(true);
                }}
                style={{ cursor: "pointer" }}
              >
                {selectedWorkout?.programs_id?.title} -{" "}
                {selectedWorkout?.workout_of_day?.title}
              </h2>
              <Tooltip title="Change workout of the day">
                <div
                  // className="back-screen-button "
                  onClick={() => {
                    setProgramSelecter(true);
                  }}
                >
                  <Icon
                    style={{ cursor: "pointer" }}
                    className="ms-2 mb-1"
                    onClick={() => {
                      setProgramSelecter(true);
                    }}
                    height={"35"}
                    width={"35"}
                    icon="bxs:edit"
                  />
                </div>
              </Tooltip>
            </div>
            <div className="col-md-1 col-sm-12 text-end">
              {selectedWorkout?.workout_of_day?.exercises.length > 6 && (
                <button
                  onClick={handlePlay}
                  className="small-contained-button me-3"
                >
                  <Icon icon="solar:play-bold" />
                </button>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="col-12 mt-2 text-end">
              <button
                onClick={handlePause}
                className="small-contained-button me-3"
              >
                <Icon icon="mingcute:pause-fill" />
              </button>
            </div>
          </>
        )}
      </div>
      <div style={{ zoom: `${zoomLevel}%` }}>
        <MySlider
          elements={
            selectedWorkout?.workout_of_day?.exercises
              ? selectedWorkout?.workout_of_day?.exercises
              : []
          }
          isPaused={isPaused}
        />
      </div>
      <div className="zoom-controls">
        <div className="me-2 cross-icon ">
          <RemoveCircleIcon
            onClick={handleZoomOut}
            className="diary_icon_plus_minus"
          />
        </div>
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          Zoom
        </Typography>
        <div className=" cross-icon ms-2">
          <AddCircleIcon
            onClick={handleZoomIn}
            className="diary_icon_plus_minus"
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="container">
      <IconButton className="back-screen-button " onClick={() => navigate(-1)}>
        <ArrowBackIcon />
      </IconButton>
      <RecordNotFound title="Today Workout" />
    </div>
  );
};

export default WorkoutDisplay;
