import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AddOrUpdateTickets from "./AddOrUpdateTickets";
import SupportTicketsTabs from "./SupportTicketsTabs";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import CustomDrawer from "src/components/CustomDrawer/CustomDrawer";

function SupportTicket() {
  const { state } = useLocation();
  const [Open, setOpen] = useState("");
  const [Solved, setSolved] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [drawerState, setDrawerState] = useState(false);
  const [userList, setUserList] = useState([]);

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };

  const handleChanges = (event, newValue) => {
    setTabValue(newValue);
  };

  const TABS_OPTIONS = [
    {
      title: `Open (${Open ? Open : 0})`,
      component: (
        <SupportTicketsTabs
          tabValue={tabValue}
          setOpen={setOpen}
          setSolved={setSolved}
          valueData={"open"}
          setUserList={setUserList}
          userList={userList}
        />
      ),
    },
    {
      title: `Closed (${Solved ? Solved : 0})`,
      component: (
        <SupportTicketsTabs
          setOpen={setOpen}
          setSolved={setSolved}
          tabValue={tabValue}
          valueData={"solved"}
          setUserList={setUserList}
          userList={userList}
        />
      ),
    },
  ];

  // useEffect(() => {
  //   if (state) {
  //     setTabValue(state);
  //     window.history.replaceState({}, document.title);
  //   }
  // }, [state]);

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-sm-12 col-md-6">
          <h2>Support Tickets</h2>
        </div>
        <div className="col-sm-12 col-md-6 text-end">
          <button
            className="small-contained-button me-2 mt-1 mb-4"
            onClick={handleOpenDrawer}
          >
            New Ticket
          </button>
        </div>
        <div className="col-12">
          <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChanges}
          />
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="New Ticket"
        componentToPassDown={
          <AddOrUpdateTickets
            formType="ADD"
            onCloseDrawer={handleCloseDrawer}
            setUserList={setUserList}
            setOpen={setOpen}
          />
        }
      />
    </div>
  );
}

export default SupportTicket;
