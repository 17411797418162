import React, { useState } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useGoogleLogin } from "@react-oauth/google";
import {
  connect_with_google,
  remove_google_access_api,
} from "src/DAL/Calender/Calender";
import { useSnackbar } from "notistack";
import { Avatar, Tooltip } from "@mui/material";
import { google_calendar_icon } from "src/assets";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { consultantProfileApi } from "src/DAL/Login/Login";

const GoogleAuth = () => {
  const { googleClientId } = useContentSetting();

  return (
    <div>
      <GoogleOAuthProvider clientId={googleClientId?.client_id}>
        <Hook />
      </GoogleOAuthProvider>
    </div>
  );
};

export default GoogleAuth;

function Hook() {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo, handleSetUserInfo } = useContentSetting();
  const [openDelete, setOpenDelete] = useState(false);

  const getContentSetting = async () => {
    const result = await consultantProfileApi(userInfo?._id);
    if (result.code === 200) {
      localStorage.setItem(`user_data`, JSON.stringify(result.coach));
      handleSetUserInfo(result.coach);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const connectGoogle = async (token) => {
    let postData = {
      code: token,
    };
    const result = await connect_with_google(postData);
    if (result.code == 200) {
      console.log(result, "from api ");
      enqueueSnackbar("Connected With Google.", { variant: "success" });
      getContentSetting();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => connectGoogle(codeResponse.code),
    flow: "auth-code",
    scope:
      "openid email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
  });

  const handleRemove = async () => {
    const result = await remove_google_access_api();
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setOpenDelete(false);
      getContentSetting();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setOpenDelete(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setOpenDelete(true);
  };

  return (
    <div>
      {userInfo.is_google_signin ? (
        <div className="d-flex me-2 position-relative">
          <Tooltip title="Change Access">
            <Avatar
              className="pointer"
              alt={userInfo?.google_account_info?.given_name}
              src={userInfo?.google_account_info?.picture}
              onClick={login}
            />
          </Tooltip>
          <Tooltip title="Remove Access">
            <span className="remove-google-account" onClick={handleAgreeDelete}>
              x
            </span>
          </Tooltip>
          {
            <div>
              <p className="mb-0 ms-2 me-2">
                {userInfo?.google_account_info?.given_name +
                  " " +
                  userInfo?.google_account_info?.family_name}
              </p>

              <p className="ms-2">{userInfo?.google_account_info?.email}</p>
            </div>
          }
        </div>
      ) : (
        <button
          className="small-contained-button google-button d-flex"
          onClick={login}
        >
          <img
            src={google_calendar_icon}
            height="25px"
            width="25px"
            className="me-2"
          />
          Sync With Google Calendar
        </button>
      )}
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to remove this account"}
        handleAgree={handleRemove}
      />
    </div>
  );
}
