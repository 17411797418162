import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  PrivateRouteWithLayout,
  // RouteWithLayout,
  PublicRouteWithLayout,
  // PrivateRouteWithLayout,
} from "./components";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import {
  ForgetPassword,
  Programmes,
  ProgrammesDetail,
  GoalStatement,
  Vault,
  Profile,
  Pods,
  Affirmations,
  Gratitude,
  Quotes,
  Memories,
  MemoriesDetails,
  DailyActivities,
  Calender,
  LessonsDetail,
  LessonListing,
  ProgrammRecordingDetail,
  AffirmationList,
} from "./pages/index";
import LessonsReSource from "./pages/lessonsResource/LessonsResource";
import ReviewCards from "./pages/ReviewCard/ReviewCards";
import Login from "./pages/Login";
import Register from "./pages/Register";
import DashboardApp from "./pages/DashboardApp";
import Products from "./pages/Products";
import Blog from "./pages/Blog";
import User from "./pages/User";
import NotFound from "./pages/Page404";
import AddGratitude from "./pages/Gratitude/AddGratitude";
import MemoriesDetail from "./pages/Memories/MemoriesDetail";
import SourcesFiles from "./pages/SourceCard/SourcesFiles";
import BobVideos from "./pages/BOBVideos/BobVideos";
import LessonsNotes from "./components/LessonNotes/LessonsNotes";
import LessonsRecording from "./pages/LessonsRecording";

import Addlesson from "./pages/Programmes/AddLesson";
import AddProgramReview from "./pages/ReviewCard/AddProgramReview";
import AddDocument from "./pages/SourceCard/AddDocument";
// import EditProgram from "./pages/Programmes/EditProgram";
// import AddLessonsDoc from "./pages/Programmes/AddLessonsDoc";
// import AddLessonsRecording from "./pages/Programmes/AddLessonRecording";
import DeleteProgram from "./components/_dashboard/programmes/Deleteprogram";
import EditLesson from "./pages/Programmes/EditLesson";
import EditProgramReview from "./pages/ReviewCard/EditReview";
import EditProgramDocument from "./pages/SourceCard/EditProgramDocument";

import Rooms from "./pages/Room/Rooms";
import AddRooms from "./pages/Room/AddRooms";
import RecordingsList from "./pages/Recordings/RecordingsList";
import AddQuestions from "./pages/GoalStatement/AddQuestions";
import EditQuestions from "./pages/GoalStatement/EditQuestion";
import AddRecording from "./pages/Recordings/AddRecording";
import EditRoom from "./pages/Room/EditRoom";
// import EditMember from "./pages/Member/EditMembers";
import EditRecording from "./pages/Recordings/EditRecording";
import EditProfile from "./pages/Profile/EditProfile";
// import Calenders from "./pages/Calender/CalendarEvents";
import RecordingDetails from "./pages/Recordings/RecordingDetail";
import RoomDetail from "./pages/Room/DetailRoom";
import Groups from "./pages/Groups/Groups";
import AddGroups from "./pages/Groups/AddGroup";
import EditGroups from "./pages/Groups/EditGroup";
import GroupDetail from "./pages/Groups/DetailGroup";
import DetailQuestion from "./pages/GoalStatement/DetailQuestion";
import Community from "./pages/Community/Community";
import MemberGoalStatement from "./pages/MemberGoalStatement/GoalStatementMembers";
import MemberGoalstatementList from "./pages/MemberGoalStatement/MemberGoalstatementList";
import { AnswerHistory } from "./pages/MemberGoalStatement/AnswerHistory";
import { QuestionsReply } from "./pages/MemberGoalStatement/QuestionsReply";

import MemberGroupsList from "./pages/MemberProfile/MembersGroupList";
import MemberPersonalNote from "./pages/MemberProfile/MemberPersonalNote";
import ChartJs from "./pages/MemberProfile/Chart";
import Todo from "./pages/MemberProfile/Todo";
import SelfImageList from "./pages/SelfImage/SelfImageList";
import AddSelfImage from "./pages/SelfImage/AddSelfImage";
import DetailSelfImage from "./pages/SelfImage/DetailSelfImage";
import EditSelfImage from "./pages/SelfImage/EditSelfImage";
import MemberGoalStatementIncomplete from "./pages/MemberGoalStatement/GoalStatementMembersInComlete";
import MemberGoalStatementSaveAndClose from "./pages/MemberGoalStatement/GoalStatementSaveAndClose";
import SelfImageMember from "./pages/MemberSelfImage/SelfImageMember";
import SelfImageMembersInComlete from "./pages/MemberSelfImage/SelfImageMembersInComlete";
import SelfImageSaveAndClose from "./pages/MemberSelfImage/SelfImageSaveAndClose";
import SelfImageGoalstatementList from "./pages/MemberSelfImage/SelfImageGoalstatementList";
import JournalList from "./pages/JournalPrompts/JournalList";
import AddJournal from "./pages/JournalPrompts/AddJournal";
import EditJournal from "./pages/JournalPrompts/EditJournal";
import DetailJournal from "./pages/JournalPrompts/DetailJournal";
import JournalGoalstatementList from "./pages/MemberJournalPrompts/JournalGoalstatementList";
import JournalMember from "./pages/MemberJournalPrompts/JournalMember";
import JournalMembersInComlete from "./pages/MemberJournalPrompts/JournalMembersInComlete";
import JournalSaveAndClose from "./pages/MemberJournalPrompts/JournalSaveAndClose";
import Calenders from "./pages/Calender/Calender";
import ExercisListing from "./pages/Exercises/ExercisListing";
import AddExercise from "./pages/Exercises/AddExercise";
import EditExercise from "./pages/Exercises/EditExercise";
import ExerciseDetail from "./pages/Exercises/ExerciseDetail";
import ExercisListingType from "./pages/ExercisesType/ExercisListingType";
import AddExerciseType from "./pages/ExercisesType/AddExerciseType";
import EditExerciseType from "./pages/ExercisesType/EditExerciseType";
import WorkoutListingType from "./pages/WorkoutType/WorkoutListingType";
import WorkoutListing from "./pages/Workout/WorkoutListingnew";

import WorkoutDetail from "./pages/Workout/WorkoutDetail";
import AddWorkoutType from "./pages/WorkoutType/AddWorkoutType";
import EditWorkoutType from "./pages/WorkoutType/EditWorkoutType";
import AddFormTemplate from "./pages/UserFormTemplate/addFormTemplate";
import EditFormTemplate from "./pages/UserFormTemplate/editFormTemplate";
import TemplatePreview from "./pages/UserFormTemplate/templatePreview";
import FormTemplates from "./pages/UserFormTemplate/FormTemplates";
import UserForm from "./pages/UserForms/UserForm";
import AddForm from "./pages/UserForms/AddForm";
import DetailsFormInformation from "./pages/UserForms/DetailsFormInformation";
import EditForm from "./pages/UserForms/EditForm";
import CoachForms from "./pages/UserInformation/CoachForms";

import DetailsCoachFormInformation from "./pages/UserInformation/DetailsCoachFormInformation";
import CoachInformationform from "./pages/UserInformation/CoachInformationform";

// import DetailsUserFormInformation from "./pages/Member/UserForms/DetailsUserFormInformation";
// import ListUserForm from "./pages/Member/UserForms/ListUserForm";
import AddWorkout from "./pages/Workout/AddWorkoutNew";
import EditWorkout from "./pages/Workout/EditWorkoutNew";
import AddProgramme from "./pages/Programmes/AddProgramme";
import EditProgramme from "./pages/Programmes/EditProgramme";
import ProgrammeBulkAccess from "./components/ProgrammBulkAccess/ProgrammeBulkAccess";
import DynamicTable from "./pages/Calender/component/FixedCalender";
import MembersList from "./pages/Members/MembersList";
import AddMember from "./pages/Members/AddMember";
import EditMember from "./pages/Members/EditMember";
import DetailsUserFormInformation from "./pages/Members/UserForms/DetailsUserFormInformation";
import ListUserForm from "./pages/Members/UserForms/ListUserForm";
import MemberProfile from "./pages/Members/MemberProfile";
import MemberCalendar from "./pages/Members/MemberCalendar";
import EditClientVideos from "./pages/HelpingVideos/EditClientVideos";
import ClientVideos from "./pages/HelpingVideos/ClientVideos";
import AddClientVideos from "./pages/HelpingVideos/AddClientVideos";
import ClientVideoDetail from "./pages/HelpingVideos/ClientVideoDetail";
import HelpingVideos from "./pages/HelpingVideos/HelpingVideos";
import HelpingVideoDetail from "./pages/HelpingVideos/HelpingVideoDetail";
import ProgramUsersForm from "./pages/Programmes/UserForms";
import WorkoutDisplay from "./pages/Banner/WorkoutDisplay";
import Feed from "./pages/CommunityFeeds/Feed";
import PendingPosts from "./pages/CommunityFeeds/PendingPosts";
import RejectedPosts from "./pages/CommunityFeeds/RejectedPosts";
import GroupsFeedsDetail from "./pages/CommunityFeeds/GroupsFeedsDetail";
import GroupMembers from "./pages/GroupsFeeds/GroupMembers";
import EditGroup from "./pages/GroupsFeeds/EditGroupFeed";
import AddGroupsFeed from "./pages/GroupsFeeds/AddGroupsFeed";
import EditGroupFeed from "./pages/GroupsFeeds/EditGroupFeed";
import GroupsFeed from "./pages/GroupsFeeds/GroupsFeed";
import MyPosts from "./pages/CommunityFeeds/MyPosts";
import UserFormAgainstProgram from "./pages/UserForms/Components/UserFormAgainstProgram";
import PostDetail from "./pages/CommunityFeeds/PostDetail";
import FeedDetailPage from "./pages/CommunityFeeds/FeedDetailPage";
import ChangePassword from "./pages/Members/ChangePassword";
import FoodMattersListing from "./pages/FoodMattersLibrary/FoodMattersListing";
import AddfoodMatters from "./pages/FoodMattersLibrary/AddfoodMatters";
import EditFoodMatters from "./pages/FoodMattersLibrary/EditFoodMatters";
import FoodMattersListingType from "./pages/FoodMattersType/FoodMattersListingType";
import AddFoodMattersType from "./pages/FoodMattersType/AddFoodMattersType";
import EditFoodMattersType from "./pages/FoodMattersType/EditFoodMattersType";
import MindSetListing from "./pages/MindSetLibrary/MindSetListing";
import AddMindset from "./pages/MindSetLibrary/AddMindset";
import EditMindset from "./pages/MindSetLibrary/EditMindset";
import MindSetListingType from "./pages/MindSetType/MindSetListingType";
import AddMindSetType from "./pages/MindSetType/AddMindSetType";
import EditMindSetType from "./pages/MindSetType/EditMindSetType";
import RecentPointsTable from "./pages/Members/RecentPointsTable";
import LoginByAdmin from "./pages/LoginByAdmin";
import AddAdminCategory from "./pages/HelpingVideos/AddAdminCategory";
import EditAdminVideosCategory from "./pages/HelpingVideos/EditAdminVideosCategory";
import AdminVideosCatagories from "./pages/HelpingVideos/AdminVideosCatagories";
import HelpingVideosCategory from "./pages/HelpingVideos/HelpingVideosCategory";
import VideoSetListing from "./pages/VideoLibrary/VideoSetListing";
import AddVideoSet from "./pages/VideoLibrary/AddVideoSet";
import EditVideoSet from "./pages/VideoLibrary/EditVideoSet";
import VideoSetListingType from "./pages/VideosType/VideoSetListingType";
import AddVideoSetType from "./pages/VideosType/AddVideoSetType";
import EditVideoSetType from "./pages/VideosType/EditVideoSetType";
import Chat from "./pages/Chat/Chat";
import MainUserFormList from "./pages/UserForms/MainUserFormList";
import UserFormMembers from "./pages/UserForms/UserFormMembers";
import CalendarEvents from "./pages/MyCalender/CalendarEvents";
import SupportTicket from "./pages/SupportTickets/SupportTicket";
import SupportTicketDetail from "./pages/SupportTickets/SupportTicketDetail";

// ----------------------------------------------------------------------
const Authentication = () => {
  console.log(localStorage.getItem("token"), "TOKEN");
  if (localStorage.getItem("token")) {
    return <Navigate to="/programmes"> </Navigate>;
  }
  if (!localStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  }
};
export default function Router() {
  return (
    <Routes>
      <Route path="/login-by-admin/:token" element={<LoginByAdmin />} />

      <Route element={<DashboardLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route path="/dashboard" element={<DashboardApp />} />
        {/* <Route path="/dashboard" element={<Community />} /> */}
        {/* start form  */}
        <Route path="/coach_forms" element={<CoachForms />} />
        <Route
          path="/coach_forms-submition/:slug"
          element={<CoachInformationform />}
        />
        <Route
          path="/coach_forms-Details/:slug/:user_id"
          element={<DetailsCoachFormInformation />}
        />
        <Route path="/form-list" element={<MainUserFormList />} />
        <Route path="/form-list/forms-send-forms" element={<AddForm />} />
        <Route
          path="/form-list/forms-edit-forms/:slug"
          element={<EditForm />}
        />
        <Route path="/form-list/forms/:id" element={<UserForm />} />
        <Route
          path="/form-list/forms/forms-preview/:slug"
          element={<DetailsFormInformation page_type="forms" />}
        />
        <Route
          path="/form-list/forms/members/:id"
          element={<UserFormMembers />}
        />
        <Route
          path="/form-list/forms/members/forms-preview/:slug/:user_id"
          element={<DetailsFormInformation page_type="members_forms" />}
        />

        <Route
          path="/members/forms-preview/:slug/:user_id"
          element={<DetailsFormInformation page_type="members_forms" />}
        />
        <Route
          path="/programmes/members/:id"
          element={<UserFormMembers type="programmes" />}
        />
        <Route
          path="/programmes/members/forms-preview/:slug/:user_id"
          element={<DetailsFormInformation page_type="members_forms" />}
        />
        {/* end form  */}

        <Route
          path="/user_form-templates/add-user_form-templates"
          element={<AddFormTemplate />}
        />
        <Route
          path="/user_form-templates/edit-user_form-templates/:id"
          element={<EditFormTemplate />}
        />
        <Route
          path="/user_form-templates/preview-user_form-templates/:id"
          element={<TemplatePreview />}
        />
        <Route path="/user_form-templates" element={<FormTemplates />} />
        <Route path="/forms" element={<UserForm />} />
        <Route path="/calendar-events" element={<CalendarEvents />} />
        <Route path="/forms/forms-send-forms" element={<AddForm />} />
        <Route
          path="/forms-preview/:slug"
          element={<DetailsFormInformation />}
        />
        <Route
          path="/forms/:fId/template/:tId/program/:id"
          element={<UserFormAgainstProgram />}
        />
        <Route
          path="/programmes/forms/:fId/template/:tId/program/:id"
          element={<UserFormAgainstProgram />}
        />
        <Route path="/forms/forms-edit-forms/:slug" element={<EditForm />} />
        <Route path="/exercise" element={<ExercisListing />} />
        <Route path="/exercise-add" element={<AddExercise />} />
        <Route path="/exercise-edit/:slug" element={<EditExercise />} />
        <Route path="/exercise-details/:slug" element={<ExerciseDetail />} />
        <Route path="/type-exercise" element={<ExercisListingType />} />
        <Route path="/type-exercise-add" element={<AddExerciseType />} />
        <Route
          path="/type-exercise-edit/:slug"
          element={<EditExerciseType />}
        />
        <Route path="/type-workout" element={<WorkoutListingType />} />
        <Route path="/workout" element={<WorkoutListing />} />
        <Route path="/workout-add" element={<AddWorkout />} />
        <Route path="/workout-edit/:slug" element={<EditWorkout />} />
        <Route path="/workout-details/:slug" element={<WorkoutDetail />} />
        <Route path="/type-workout-add" element={<AddWorkoutType />} />
        <Route path="/type-workout-edit/:slug" element={<EditWorkoutType />} />
        {/*  */}
        <Route path="/food_matters" element={<FoodMattersListing />} />
        <Route path="/food_matters-add" element={<AddfoodMatters />} />
        <Route path="/food_matters-edit/:slug" element={<EditFoodMatters />} />
        <Route path="/food_matters_type" element={<FoodMattersListingType />} />
        <Route path="/food_matters_type-add" element={<AddFoodMattersType />} />
        <Route
          path="/food_matters_type-edit/:slug"
          element={<EditFoodMattersType />}
        />
        <Route path="/mindset" element={<MindSetListing />} />
        <Route path="/mindset-add" element={<AddMindset />} />
        <Route path="/mindset-edit/:slug" element={<EditMindset />} />
        <Route path="/mindset_type" element={<MindSetListingType />} />
        <Route path="/mindset_type-add" element={<AddMindSetType />} />
        <Route path="/mindset_type-edit/:slug" element={<EditMindSetType />} />

        <Route path="/videoset" element={<VideoSetListing />} />
        <Route path="/videoset-add" element={<AddVideoSet />} />
        <Route path="/videoset-edit/:slug" element={<EditVideoSet />} />
        <Route path="/videoset_type" element={<VideoSetListingType />} />
        <Route path="/videoset_type-add" element={<AddVideoSetType />} />
        <Route
          path="/videoset_type-edit/:slug"
          element={<EditVideoSetType />}
        />
        {/*  */}
        <Route path="/programmes" element={<Programmes type="active" />} />
        <Route
          path="/inactive-programmes"
          element={<Programmes type="inactive" />}
        />
        <Route
          path="/programmes/programmes-form/:id"
          element={<ProgramUsersForm />}
        />
        <Route
          path="/programmes/programmes-form/forms-preview/:slug"
          element={<DetailsFormInformation page_type="forms" />}
        />
        <Route path="/Editprofile" element={<EditProfile />} />
        {/* <Route path="/programmes/:slug" element={<ProgrammesDetail />} /> */}
        <Route path="/members" element={<MembersList />} />
        <Route
          path="/member/change-password/:id"
          element={<ChangePassword />}
        />
        {/* <Route path="/member" element={<MembersList />} /> */}
        <Route path="/member/edit-member/:id" element={<EditMember />} />
        <Route path="/member/add-member" element={<AddMember />} />
        <Route
          path="/members/List-forms/:id/form/:slug"
          element={<DetailsFormInformation />}
        />
        <Route
          path="/programmes/forms-preview/:slug"
          element={<DetailsFormInformation />}
        />
        <Route
          path="/members/List-forms/:id"
          element={<ListUserForm type="members" />}
        />
        <Route path="/member/member-profile/:id" element={<MemberProfile />} />
        <Route
          path="/member/member-points/:id"
          element={<RecentPointsTable />}
        />
        <Route
          path="/member/:id/member-MemberCalendar/:CalId"
          element={<MemberCalendar />}
        />
        <Route
          path="/members/goalstatement/:id"
          element={<MemberGoalstatementList />}
        />
        <Route path="/members/profile/:id" element={<MemberProfile />} />
        <Route path="/members/groups/:id" element={<MemberGroupsList />} />
        <Route path="/members/to-do/:id" element={<Todo />} />
        <Route
          path="/members/personal_note/:id"
          element={<MemberPersonalNote />}
        />
        <Route path="/member-goalstatement" element={<MemberGoalStatement />} />
        <Route
          path="/member-completed-goalstatements/:id"
          element={<MemberGoalstatementList />}
        />
        <Route
          path="/member-incomplete-goalstatements/:id"
          element={<MemberGoalstatementList />}
        />
        <Route
          path="/member-responded-goalstatements/:id"
          element={<MemberGoalstatementList />}
        />
        <Route
          path="/member-completed-selfImage/:id"
          element={<SelfImageGoalstatementList />}
        />
        <Route
          path="/member-incomplete-selfImage/:id"
          element={<SelfImageGoalstatementList />}
        />
        <Route
          path="/member-responded-selfImage/:id"
          element={<SelfImageGoalstatementList />}
        />
        <Route
          path="/member-completed-journalPrompt/:id"
          element={<JournalGoalstatementList />}
        />
        <Route
          path="/member-incomplete-journalPrompt/:id"
          element={<JournalGoalstatementList />}
        />
        <Route
          path="/member-responded-journalPrompt/:id"
          element={<JournalGoalstatementList />}
        />
        <Route
          path="/member-goalstatement/answer-history/:id"
          element={<AnswerHistory />}
        />
        <Route
          path="/member-goalstatement/question-reply/:id"
          element={<QuestionsReply />}
        />
        {/* <Route path="/members/edit-members/:slug" element={<EditMember />} /> */}
        <Route path="/links" element={<Rooms />} />
        <Route path="/links/add-link" element={<AddRooms />} />
        <Route path="/links/edit-link" element={<EditRoom />} />
        <Route path="/recordings" element={<RecordingsList />} />
        <Route path="/recordings/add-recording" element={<AddRecording />} />
        <Route path="/recordings/Edit-recording" element={<EditRecording />} />
        <Route path="/goal-statement/add-question" element={<AddQuestions />} />
        <Route
          path="/goal-statement/edit-question/:slug"
          element={<EditQuestions />}
        />
        <Route
          path="/goal-statement/detail-question/:slug"
          element={<DetailQuestion />}
        />
        <Route
          path="/member-completed-goalstatements"
          element={<MemberGoalStatement />}
        />
        <Route
          path="/member-completed-selfImage"
          element={<SelfImageMember />}
        />
        <Route
          path="/member-completed-journalPrompt"
          element={<JournalMember />}
        />
        <Route
          path="/member-incomplete-goalstatements/"
          element={<MemberGoalStatementIncomplete />}
        />
        <Route
          path="/member-incomplete-selfImage/"
          element={<SelfImageMembersInComlete />}
        />
        <Route
          path="/member-incomplete-journalPrompt/"
          element={<JournalMembersInComlete />}
        />
        <Route
          path="/member-responded-goalstatements"
          element={<MemberGoalStatementSaveAndClose />}
        />
        <Route
          path="/member-responded-selfImage"
          element={<SelfImageSaveAndClose />}
        />
        <Route
          path="/member-responded-journalPrompt"
          element={<JournalSaveAndClose />}
        />
        <Route
          path="programmes/lessons_detail/resources/:slug"
          element={<LessonsReSource />}
        />
        <Route
          path="/programmes/deleteProgram/:slug"
          element={<DeleteProgram />}
        />
        <Route path="/lessons/:id" element={<LessonListing />} />
        <Route path="/review/:slug" element={<ReviewCards />} />
        <Route
          path="/programmes/addReview/:slug"
          element={<AddProgramReview />}
        />
        <Route
          path="/programmes/editProgrammeReview/:programSlug/:reviewSlug"
          element={<EditProgramReview />}
        />
        <Route
          path="/programmes/edit-programme/:programme_slug"
          element={<EditProgramme />}
        />
        <Route path="/programmes/:type/:id" element={<ProgrammeBulkAccess />} />
        <Route
          path="/programmes/programmes-detail/:id"
          element={<ProgrammesDetail />}
        />
        <Route path="/programmes-calendar/:slug" element={<Calenders />} />
        <Route path="/chat" element={<Chat />} />
        <Route
          path="/programmes-limited-calendar/:slug"
          element={<DynamicTable />}
        />
        {/* <Route path="/programmes/editprogram/:id" element={<EditProgram />} />
        <Route path="/programmes/editprogram/:slug" element={<EditProgram />} /> */}
        <Route
          path="/programmes/editresource/:programSlug/:sourceSlug"
          element={<EditProgramDocument />}
        />
        <Route path="/programmes/sources/:slug" element={<SourcesFiles />} />
        <Route path="/programmes/Addsources/:id" element={<AddDocument />} />
        <Route path="/groups/add-group" element={<AddGroups />} />
        <Route path="/groups/edit-group" element={<EditGroups />} />
        <Route path="/groups/detail-group" element={<GroupDetail />} />
        <Route path="/groups" element={<Groups />} />
        <Route
          path="/programmes/lesson_sources/:programslug/:lessonSlug"
          element={<LessonsReSource />}
        />
        {/* <Route
          path="/lessons/addlessonsDocument/:id"
          element={<AddLessonsDoc />}
        /> */}
        <Route path="/lessons/addlesson/:slug" element={<Addlesson />} />
        <Route
          path="/lessons/Editlesson/:lessonSlug"
          element={<EditLesson />}
        />
        {/* <Route
          path="/lessons/addlessonsRecording/:programSlug/:lessonSlug"
          element={<AddLessonsRecording />}
        /> */}
        <Route path="/programmes/lesson/notes/:id" element={<LessonsNotes />} />
        <Route path="/programmes/BobVideos/:id" element={<BobVideos />} />
        <Route
          path="/programmes/lessons_detail/:slug1/:slug"
          element={<LessonsDetail />}
        />
        <Route
          path="/lesson_recording_detail/:lessonSlug"
          element={<LessonsRecording />}
        />
        <Route
          path="/programmes/programm_recording_detail/48ce0817-9219-4977-9149-168e020ad288"
          element={<ProgrammRecordingDetail />}
        />
        <Route path="/programmes/add-programme" element={<AddProgramme />} />
        <Route path="/goal-statement" element={<GoalStatement />} />
        <Route path="/vault" element={<Vault />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        {/* <Route path="/pods" element={<Pods />} /> */}
        <Route path="/support-ticket" element={<SupportTicket />} />
        <Route path="/support-ticket/:id" element={<SupportTicketDetail />} />
        <Route path="/affirmations" element={<Affirmations />} />
        <Route
          path="/affirmations/affirmationList"
          element={<AffirmationList />}
        />
        <Route path="/gratitude" element={<Gratitude />} />
        <Route path="/gratitude/addgratitude" element={<AddGratitude />} />
        <Route path="/quotes" element={<Quotes />} />
        <Route path="/memories" element={<Memories />} />
        <Route
          path="/memories/memories_detail/:id"
          element={<MemoriesDetail />}
        />
        <Route path="/daily_activities" element={<DailyActivities />} />
        {/* self image  */}
        <Route path="/self-image" element={<SelfImageList />} />
        <Route path="/journal-prompts" element={<JournalList />} />
        <Route path="/self-image/add-self-image" element={<AddSelfImage />} />
        <Route
          path="/journal-prompt/add-journal-prompt"
          element={<AddJournal />}
        />
        <Route
          path="/self-image/edit-self-image/:slug"
          element={<EditSelfImage />}
        />
        <Route
          path="/journal-prompt/edit-journal-prompt/:slug"
          element={<EditJournal />}
        />
        <Route
          path="/self-image/detail-self-image/:slug"
          element={<DetailSelfImage />}
        />
        <Route
          path="/journal-prompt/detail-journal-prompt/:slug"
          element={<DetailJournal />}
        />
        {/* self image end */}
        {/* <Route path="/calender" element={<Calender />} /> */}
        <Route path="/programmes-calendar" element={<Calenders />} />
        <Route
          path="/recordings/detail-recording"
          element={<RecordingDetails />}
        />
        {/* <Route path="/room/detail-room" element={<RoomDetail />} /> */}
        <Route path="/links/detail-link" element={<RoomDetail />} />
        <Route
          path="/member-videos/edit-member-videos"
          element={<EditClientVideos />}
        />
        <Route path="/member-videos" element={<ClientVideos />} />
        <Route
          path="/members_videos_category/members-videos/:id"
          element={<ClientVideos />}
        />
        <Route
          path="/members-videos/add-member-videos/:id"
          element={<AddClientVideos />}
        />
        <Route
          path="/members_videos_category/add"
          element={<AddAdminCategory />}
        />
        <Route
          path="/members_videos_category/edit/:id"
          element={<EditAdminVideosCategory />}
        />
        <Route
          path="/members_videos_category"
          element={<AdminVideosCatagories />}
        />
        <Route
          path="/helping_videos_category"
          element={<HelpingVideosCategory />}
        />

        <Route
          path="/members-videos/members-videos-detail/:id"
          element={<ClientVideoDetail />}
        />
        <Route
          path="/helping_videos_category/helping-videos/:id"
          element={<HelpingVideos />}
        />
        <Route
          path="/helping_videos_category/:id/helping-videos/:slug"
          element={<HelpingVideoDetail />}
        />
        {/* feeds */}
        <Route path="/community-area" element={<Feed />} />
        <Route path="/pending-posts" element={<PendingPosts />} />
        <Route path="/rejected-posts" element={<RejectedPosts />} />
        <Route path="/pending-posts/post-detail" element={<PostDetail />} />
        <Route path="/rejected-posts/post-detail" element={<PostDetail />} />
        <Route
          path="/community-area-post-detail/:id"
          element={<FeedDetailPage />}
        />
        {/* groups */}
        <Route path="/feed-groups" element={<GroupsFeed />} />
        <Route path="/feed-groups/add-group" element={<AddGroupsFeed />} />
        <Route
          path="/feed-groups/edit-group/:slug"
          element={<EditGroupFeed />}
        />
        <Route path="/groups/group-members/:slug" element={<GroupMembers />} />
        <Route
          path="/community-area/groups/group-members/:slug"
          element={<GroupMembers />}
        />
        <Route path="/group-feed/:id" element={<GroupsFeedsDetail />} />
        <Route path="/my-posts" element={<MyPosts />} />
      </Route>
      <Route element={<LogoOnlyLayout />}>
        <Route path="/" element={<Authentication />} />
        {/* <Route path="/register" element={<Register />} /> */}
        {/* <Route path="404" element={<NotFound />} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/forget_password" element={<ForgetPassword />} />
        {/* <Route path="*" element={<NotFound />} /> */}
        <Route path="/WorkoutDisplay" element={<WorkoutDisplay />} />
        <Route path="404" element={<Authentication />} />
        <Route path="*" element={<Authentication />} />
      </Route>
      {/* <PublicRouteWithLayout component={Login} exact layout={LogoOnlyLayout} path="/login" />
      <PublicRouteWithLayout component={Register} exact layout={LogoOnlyLayout} path="/register" /> */}
      {/* --------------------------------------- Dashboard --------------------------------------- */}
      {/* <PrivateRouteWithLayout
        component={DashboardApp}
        exact
        layout={DashboardLayout}
        path="/dashboard/app"
      /> */}
      {/* --------------------------------------- Change Password --------------------------------------- */}
      {/* <PrivateRouteWithLayout
        component={Products}
        exact
        layout={DashboardLayout}
        path="/dashboard/products"
      /> */}
    </Routes>
  );
}
//   return useRoutes([
//     {
//       path: '/dashboard',
//       element: <DashboardLayout />,
//       children: [
//         { element: <Navigate to="/dashboard/app" replace /> },
//         { path: 'app', element: <DashboardApp /> },
//         { path: 'user', element: <User /> },
//         { path: 'products', element: <Products /> },
//         { path: 'blog', element: <Blog /> }
//       ]
//     },
//     {
//       path: '/',
//       element: <LogoOnlyLayout />,
//       children: [
//         { path: 'login', element: <Login /> },
//         { path: 'register', element: <Register /> },
//         { path: '404', element: <NotFound /> },
//         { path: '/', element: <Navigate to="/dashboard" /> },
//         { path: '*', element: <Navigate to="/404" /> }
//       ]
//     },
//     { path: '*', element: <Navigate to="/404" replace /> }
//   ]);
// }
