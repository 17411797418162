import React, { useEffect, useState } from "react";
import { Button, IconButton, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { s3baseUrl } from "src/config/config";
import Label from "src/components/Label";
import ProgrammeListMemberProfile from "./ProgrammeListMemberProfile";
import {
  memberAllDetailApi,
  memberDetailApi,
  memberPodsListing,
} from "src/DAL/member/Member1";
import PodsListMemberProfile from "./PodsListMemberProfile";
import { useSnackbar } from "notistack";
import { CircularProgress, Container, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import RecordNotFound from "src/components/RecordNotFound";
import MemberCalenders from "./MemberEventCalendar";
import { dummyImage } from "src/assets";
import ActiveLastBreadcrumb from "src/components/ActivePaths";
import GeneralCalendar from "src/components/GeneralComponents/GeneralCalendar";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import moment from "moment/moment";
import { difference_between_two_dates } from "src/utils/constant";
import GeneralCalendarClient from "src/components/GeneralComponents/GeneralCalendarClient";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { consultantProfileApi } from "src/DAL/Login/Login";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const get_view_dates = (calendarValue) => {
  let tool = calendarValue;
  // if(calendarValue=="month")

  const startOfMonth = moment().startOf(tool);
  // console.log(startOfMonth, "asfjlks");
  const daysToSubtract = startOfMonth.day() - 0; // 0 represents Sunday
  const previousSunday = startOfMonth.subtract(daysToSubtract, "days");
  let previousSundayDate = previousSunday.toDate();
  let add_days = 42;
  if (tool == "week") {
    add_days = 6;
  } else if (tool == "day") {
    add_days = 0;
  }
  let nextSaturday = startOfMonth.add(add_days, "days");
  let nextSaturdayDate = nextSaturday.toDate();
  if (tool == "day") {
    previousSundayDate = new Date();
    nextSaturdayDate = new Date();
  }

  return {
    start_date: previousSundayDate,
    end_date: nextSaturdayDate,
  };
};
const MemberProfile = () => {
  const { state } = useLocation();
  const { userInfo, handleSetUserInfo } = useContentSetting();
  const member_id = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  // console.log(params, "params for Data");
  const navigate = useNavigate();
  const [pods, setPods] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [eventsList, setEventList] = useState([]);
  const [memberAllDetails, setMemberAllDetails] = useState();
  const [memberDetail, setMemberDetail] = useState();
  const [currentDate, setCurrentDate] = useState(get_view_dates("month"));
  const [workouts, setworkouts] = useState([]);
  const [reorderWorkouts, setReorderWorkouts] = useState([]);
  const [isPasted, setIsPasted] = useState(false);
  const [isCalenderLoading, setIsCalenderLoading] = useState(true);
  const [defaultTimeValue, setDefaultTimeValue] = useState("Asia/Karachi");
  const [value, setValue] = React.useState("1");
  const [difficultyProgram, setDifficultyProgram] = useState([]);
  const [memberPoints, setMemberPoints] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let breadCrumbMenu = [
    {
      title: "Members",
      navigation: `/member`,
      active: false,
    },

    {
      title: "Member Profile",
      navigation: null,
      active: true,
    },
  ];

  const handleScrollCalled = (data) => {
    const todayCell = document.querySelector(
      `.calendar-workout-card[id="${data}"]`
    );
    if (todayCell)
      todayCell.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const getProfile = async (reloadId, message, setOpenCopyPasteConfig) => {
    const result = await consultantProfileApi(userInfo?._id);
    //console.log(result, "profile Api");
    if (result.code == 200) {
      console.log("sdkjasdfhkjas", result);
      localStorage.setItem(`user_data`, JSON.stringify(result.coach));
      localStorage.setItem(`admin_time_zone`, JSON.stringify(result.time_zone));
      // console.log(result, "resultresultresult");
      handleSetUserInfo(result.coach);
    }
  };
  const podsList = async () => {
    const result = await memberAllDetailApi(member_id.id);

    if (result.code == 200) {
      setIsLoading(false);
      setMemberPoints(result?.reward_points);

      setMemberAllDetails(result.member);
      console.log(result, "sdljkfjklsdjklfja");
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const memberDetailInfo = async (
    reloadId,
    message,
    setOpenCopyPasteConfig
  ) => {
    let apiData = {
      start_date: moment(currentDate.start_date).format("YYYY-MM-DD"),
      end_date: moment(currentDate.end_date).format("YYYY-MM-DD"),
      program: "",
    };
    const difference = difference_between_two_dates(
      apiData.start_date,
      apiData.end_date,
      "days"
    );

    if (difference < 10) {
      setIsCalenderLoading(true);
    }

    let type = "calendar";

    const result = await memberDetailApi(member_id.id, apiData, type);
    if (result.code == 200) {
      console.log(result, "membermember");
      setDefaultTimeValue(result.time_zone);
      const mergedData = [
        ...result?.workouts,
        ...result?.program_workouts.filter(
          (item) =>
            !result?.workouts.find(
              (existingItem) => existingItem.workout_id === item._id
            )
        ),
      ];

      setDifficultyProgram(result?.disabledDifficultyPrograms);
      setworkouts(mergedData);
      setReorderWorkouts(result?.reordered_workouts);

      if (reloadId) {
        handleScrollCalled(reloadId);
      }
      if (message) {
        enqueueSnackbar(message, { variant: "success" });
      }
      if (setOpenCopyPasteConfig) {
        setOpenCopyPasteConfig(false);
      }
      setIsPasted(false);
    } else {
      setIsLoading(false);
      setIsPasted(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleGroupNavigate = () => {
    //console.log(member_id, "handleGroupNavigate");
    navigate(`/member/member-groups/${member_id.id}`);
  };
  const handleNotesNavigate = () => {
    //console.log(member_id, "handleGroupNavigate");
    navigate(`/member/member-personal-notes/${member_id.id}`, {
      state: memberAllDetails,
    });
  };
  const handleGoalStatementNavigate = () => {
    //console.log(member_id, "handleGroupNavigate");
    navigate(`/member/member-goal-statement/${member_id.id}`, {
      state: memberDetail,
    });
  };
  const handleToDo = () => {
    //console.log(member_id, "handleGroupNavigate");
    navigate(`/member/to-do-tracker/${member_id.id}`, {
      state: memberDetail,
    });
  };

  useEffect(() => {
    podsList();
  }, []);
  useEffect(() => {
    getProfile();
    memberDetailInfo();
  }, [currentDate]);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  //console.log(s3baseUrl + profile_image, "s3baseUrl + profile_image");
  return (
    <div className="container">
      <div className="row member-profile">
        <div className="col-12 mb-4">
          <h2>
            <IconButton
              className="back-screen-button me-2"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
            Member Profile
          </h2>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-3 member-profile-image text-center mb-5">
          <img
            className="rounded-circle ms-auto me-auto"
            height="200px"
            width="200px"
            src={
              memberAllDetails?.profile_image === undefined ||
              memberAllDetails?.profile_image === ""
                ? dummyImage
                : s3baseUrl + memberAllDetails?.profile_image
            }
          />
        </div>
        <div className="col-sm-12 col-md-4 col-lg-5 my-auto">
          <div className="row d-flex">
            <div className="col-6 text-start">
              <h6 className="mb-3">
                <b>Name:</b>
              </h6>
              <h6 className="mb-3">
                <b>Status:</b>
              </h6>
              <h6 className="mb-3">
                <b>Email:</b>
              </h6>
              <h6 className="mb-3">
                <b>Address:</b>
              </h6>
              <h6 className="mb-3">
                <b>Total Points:</b>
              </h6>
            </div>
            <div className="col-6 text-start">
              <h6 className="mb-3">
                {memberAllDetails?.first_name +
                  " " +
                  memberAllDetails?.last_name}
              </h6>
              <h6 className="mb-3">
                <Label
                  variant="ghost"
                  color={
                    memberAllDetails?.status === false ? "error" : "success"
                  }
                >
                  {memberAllDetails?.status === false ? "InActive" : "Active"}
                </Label>
              </h6>
              <h6 className="mb-3">
                <div
                  dangerouslySetInnerHTML={{
                    __html: memberAllDetails?.email,
                  }}
                ></div>
              </h6>
              <h6 className="mb-3">{memberAllDetails?.street}</h6>
              <h6 className="mb-3">
                {memberPoints?.total_points ? memberPoints?.total_points : 0}
              </h6>
            </div>
          </div>
        </div>
      </div>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Programs" value="1" />
              <Tab label="Programming" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1" style={{ padding: "0px" }}>
            <div className="row mt-3">
              {memberAllDetails?.program.length < 1 ? (
                <>
                  <h2>Programmes</h2>
                  <RecordNotFound title="Programmes" />
                </>
              ) : (
                <ProgrammeListMemberProfile
                  programmes={memberAllDetails?.program.filter(
                    (item) => item._id !== null
                  )}
                  Id={member_id.id}
                  member_vdot={memberAllDetails?.vdot?.vdot_value}
                />
              )}
            </div>
          </TabPanel>
          <TabPanel value="2" style={{ padding: "0px" }} className="mt-3">
            <GeneralCalendarClient
              programWorkoutList={workouts}
              reorderedWorkoutList={reorderWorkouts}
              setCurrentDate={setCurrentDate}
              showAddEvent={true}
              time_zone={defaultTimeValue}
              currentDate={currentDate}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              reloadList={memberDetailInfo}
              calendar_title="Calendar"
              programme={{
                ...memberAllDetails,
                difficult_program: difficultyProgram,
              }}
              member_id={member_id.id}
              member_vdot={memberAllDetails?.vdot?.vdot_value}
              isPasted={isPasted}
              setIsPasted={setIsPasted}
              difficultyProgram={difficultyProgram}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default MemberProfile;
